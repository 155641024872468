import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Header from "../layout/Header";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const images = [
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
];

export class Portfolio extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        <section className="main-background">
          <div className="ttm-page-title-row">
            <div className="container-xl">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-box text-center">
                    <div className="page-title-heading">
                      <h1 className="title ">Portfolio</h1>
                    </div>
                    {/* /.page-title-captions */}
                    <div className="breadcrumb-wrapper">
                      <span>
                        <a title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                          <i className="ti ti-home " />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep ">&nbsp; : : &nbsp;</span>
                      <span className=" ">portfolio</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </div>
          {/* portfolio-section */}
          <section className="bg-img-portfolio ttm-row bottomzero-padding-section position-relative clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-md-12">
                  {/* section title */}
                  <div className="section-title text-center with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        Check out our{" "}
                        <span className="font-primary">Portfolio</span>
                      </h2>
                    </div>
                  </div>
                  {/* section title end */}
                </div>
              </div>
              {/* row */}
              <div className="row mt-5 mb-5">
                <div className="col-md-6 col-lg-6">
                  <div className="featured-icon-box style5 text-left res-767-mb-20 backgroud-primary p-5">
                    <div className="ttm-historybox-title mb-4">
                      <h4 className="font-dark">Web2 Security Experience</h4>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      <h6 className="font-dark mb-4">
                        {" "}
                        With providing the core level security service, our team
                        has experience of..
                      </h6>
                      <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                        <li className="mb-2">
                          <i className=" font-dark fa fa-arrow-circle-right" />
                          <span className=" font-dark ttm-list-li-content">
                            Being one of the Leading Auditor of ITGC Audit team
                            for{" "}
                            <span className="font-highlight">
                              India's Largest Pharmaceutical Company,
                            </span>{" "}
                            Virtual Caim Team has also carried out ITGC audits
                            for{" "}
                            <span className="font-highlight">
                              {" "}
                              prominent national banks,
                            </span>{" "}
                            as well as a legacy company in the{" "}
                            <span className="font-highlight">
                              Indian textile industry.
                            </span>
                          </span>
                        </li>
                        <li className="mb-2">
                          <i className="font-dark fa fa-arrow-circle-right" />
                          <span className="font-dark ttm-list-li-content">
                            Our team brings a diverse skill set, spanning{" "}
                            <span className="font-highlight">
                              governance, technical, and regulatory domains,
                            </span>
                            with experience that includes collaborating with
                            leading IT and security brands and successfully
                            completing{" "}
                            <span className="font-highlight">
                              over 125 bank audits in previous roles.
                            </span>
                          </span>
                        </li>
                        <li className="mb-2">
                          <i className="font-dark fa fa-arrow-circle-right" />
                          <span className="font-dark ttm-list-li-content">
                            Virtual Caim contributed to the creation of security
                            policies for a{" "}
                            <span className="font-highlight">
                              leading Internet Service Provider in the Gulf
                              region
                            </span>{" "}
                            and conducted Blackbox and Greybox testing for one
                            of the most{" "}
                            <span className="font-highlight">
                              established business corporations in the United
                              Arab Emirates.
                            </span>
                          </span>
                        </li>
                        <li className="mb-2">
                          <i className="font-dark fa fa-arrow-circle-right" />
                          <span className="font-dark ttm-list-li-content">
                            Providing Outsourced Service in Security Domain for
                            some of the Top Security Agencies in{" "}
                            <span className="font-highlight">
                              India & around the Globe
                            </span>
                          </span>
                        </li>
                        <li className="mb-2">
                          <i className="font-dark fa fa-arrow-circle-right" />
                          <span className="font-dark ttm-list-li-content">
                            Having BSI's Lead Auditing Certification with our
                            Core Team members can easily handle any of{" "}
                            <span className="font-highlight">
                              Risk Assessment, IT Security, Compliance, IT Asset
                              Management,etc.
                            </span>{" "}
                            Audits
                          </span>
                        </li>
                        <li className="mb-2">
                          <i className="font-dark fa fa-arrow-circle-right" />
                          <span className="font-dark ttm-list-li-content">
                            As a third-party contractor in the Cybersecurity
                            domain for one of the{" "}
                            <span className="font-highlight">
                              Big 4 audit firms - Deloitte Touché Tohmatsu
                              India,
                            </span>
                            Virtual Caim has conducted audits for major{" "}
                            <span className="font-highlight">
                              Public Sector Undertakings,
                            </span>{" "}
                            one of the{" "}
                            <span className="font-highlight">
                              largest automobile manufacturers,
                            </span>{" "}
                            and some of{" "}
                            <span className="font-highlight">
                              India’s most prominent new-age startups.
                            </span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="featured-icon-box style5 text-left res-767-mb-20 backgroud-primary p-5">
                    <div className="ttm-historybox-title mb-4">
                      <h4 className="font-dark">Web3 Security Experience</h4>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      <h6 className="font-dark mb-4">
                        {" "}
                        Besides the inception of wide acceptance of Blockchain
                        Technology we started our journey in the Blockchain
                        Security back in 2021, and have done..
                      </h6>
                      <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                        <li className="mb-2">
                          <i className=" font-dark fa fa-arrow-circle-right" />
                          <span className=" font-dark ttm-list-li-content">
                            More than{" "}
                            <span className="font-highlight">
                              250+ Smart Contract Audits
                            </span>{" "}
                            since inception.
                          </span>
                        </li>
                        <li className="mb-2">
                          <i className=" font-dark fa fa-arrow-circle-right" />
                          <span className=" font-dark ttm-list-li-content">
                            <span className="font-highlight">
                              12+ Complete Source Code Reviews
                            </span>{" "}
                            for the Defi Platforms
                          </span>
                        </li>
                        <li className="mb-2">
                          <i className=" font-dark fa fa-arrow-circle-right" />
                          <span className=" font-dark ttm-list-li-content">
                            One of very 1st Initiator of{" "}
                            <span className="font-highlight">Fuzz Testing</span>
                            in India
                          </span>
                        </li>
                        <li className="mb-2">
                          <i className=" font-dark fa fa-arrow-circle-right" />
                          <span className=" font-dark ttm-list-li-content">
                            Engaging with total of 160+ Clients, with{" "}
                            <span className="font-highlight">
                              World Domination of 20%{" "}
                            </span>
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className=" font-dark fa fa-arrow-circle-right" />
                          <span className=" font-dark ttm-list-li-content">
                            Providing Outsourced Service in Blockchain Security
                            Domain for some of the Top Security Agencies in{" "}
                            <span className="font-highlight">
                              India & around the Globe
                            </span>
                          </span>
                          <br />
                          <br />
                          <br />
                          <br />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* PortFolio temporary hidden at portfolio-hidden.txt*/}
            </div>
          </section>
          {/* portfolio-section end */}
        </section>
      </div>
    );
  }
}

export default Portfolio;

import React, { Component } from "react";
import Header from "../layout/Header";

export class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <section className="main-background">
          <div className="ttm-page-title-row">
            <div className="container-xl">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-box text-center">
                    <div className="page-title-heading">
                      <h1 className="title">Privacy Policy</h1>
                    </div>
                    {/* /.page-title-captions */}
                    <div className="breadcrumb-wrapper">
                      <span>
                        <a title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>Privacy Policy</span>
                    </div>
                  </div>
                </div>
                {/* /.col-md-12 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </div>
          {/* page-title end*/}
          <section className="ttm-row grid-section clearfix mt-0 pt-0">
            <div className="container-xl">
              <div className="row">
                {/* row */}
                <div className="col-lg-8 offset-lg-2">
                  {/* section-title */}
                  <div className="section-title with-desc text-center clearfix">
                    <p>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        Effective Date: September 10, 2024.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        Last Updated On: August 21, 2024.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          1.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            GENERAL
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          a.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          This Website with the URL of&nbsp;
                        </span>
                        <a href="http://virtualcaim.com/">
                          <u>
                            <span
                              style={{
                                fontFamily: "Arial",
                                color: "rgb(5,99,193)",
                                textDecoration: "underline",
                              }}
                            >
                              http://virtualcaim.com/
                            </span>
                          </u>
                        </a>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          &nbsp;is operated by Virtual Caim Private Limited. We
                          are committed to protecting and respecting your
                          privacy. We do collect your personal information and
                          process your personal data in accordance with the IT
                          Act, 2000 (21 of 2000) and other national and state
                          laws which relate to the processing of personal data.
                          Please read the following carefully to understand our
                          views and practices regarding your personal data.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          b.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We collect your personal information in order to
                          provide and continually improve our products and
                          services.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          c.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Our privacy policy is subject to change at any time
                          without notice. To make sure you are aware of any
                          changes, please review this policy periodically.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          d.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          All partner firms and any third-party working with or
                          for Us, and who have access to personal information,
                          will be expected to read and comply with this policy.
                        </span>
                      </li>
                    </ul>
                    <p
                      style={{
                        textIndent: "36.0000pt",
                        textAlign: "justify",
                      }}
                    >
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          2.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            HOW WE COLLECT THE INFORMATION
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          a.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          From you directly and through this Site: We may
                          collect information through the Website when you
                          visit. The data we collect depends on the context of
                          your interactions with our website.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          b.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Through business interaction: We may collect
                          information through business interaction with you or
                          your employees.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          c.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          From other sources: We may receive information from
                          other sources, such as public databases; joint
                          marketing partners; social media platforms; or other
                          third parties such as:
                        </span>
                      </li>
                    </ul>
                    <ul style={{ marginLeft: 40, textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          i.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Information about your interactions with the products
                          and services offered by our subsidiaries.
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>

                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          3.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            INFORMATION WE COLLECT
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          a.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We collect information primarily to provide better
                          services to all of our customers.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          b.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We collect the following information from you when you
                          use or signup on our website:
                        </span>
                      </li>
                    </ul>
                    <p style={{ marginLeft: 40, textAlign: "justify" }}>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        Name,
                      </span>
                    </p>
                    <p style={{ marginLeft: 40, textAlign: "justify" }}>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        Email address,
                      </span>
                    </p>
                    <p style={{ marginLeft: 40, textAlign: "justify" }}>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        Phone number,
                      </span>
                    </p>
                    <p style={{ marginLeft: 40, textAlign: "justify" }}>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        Postal address,
                      </span>
                    </p>
                    <p style={{ marginLeft: 40, textAlign: "justify" }}>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        Identity proof (For KYC),
                      </span>
                    </p>
                    <p style={{ marginLeft: 40, textAlign: "justify" }}>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        Address proof (For KYC), etc.
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          c.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          When you visit our Site, some information is
                          automatically collected. This may include information
                          such as the Operating Systems (OS) running on your
                          device, Internet Protocol (IP) address, access times,
                          browser type, and language, and the website you
                          visited before our Site. We also collect information
                          about how you use Our products or services.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          d.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We automatically collect purchase or content use
                          history, which we sometimes aggregate with similar
                          information from other customers to create features
                          such as Best Seller, Top Rated, etc.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          e.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          The full Uniform Resource Locators (URL) clickstream
                          to, through and from our website (including date and
                          time); cookie number; products and/or content you
                          viewed or searched for; page response times; download
                          errors; length of visits to certain pages; page
                          interaction information (such as scrolling, clicks,
                          and mouse-overs).
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          f.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We automatically collect information using "Cookies".
                          Cookies are small data files stored on your hard
                          drive. Among other things, cookies help us improve our
                          Site, our marketing activities, and your experience.
                          We use cookies to see which areas and features are
                          popular and to count visits to our Site.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          g.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Most Web browsers are set to accept cookies by
                          default. If you prefer, you can choose to set your
                          browser to remove cookies and to reject cookies. If
                          you set your browser to reject cookies, some features
                          will be unavailable. For more information on how to
                          reject cookies, see your browser's instructions on
                          changing your cookie settings.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          h.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          By using this Website, you are agreeing that We may
                          advertise your feedback on the Website and marketing
                          materials.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          i.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We will retain your information as long as we require
                          this to provide you with the goods and services and
                          for such period as mandated by the laws concerned.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          j.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          If you opt to receive marketing correspondence from
                          us, subscribe to our mailing list or newsletters,
                          enter into any of our competitions or provide us with
                          your details at networking events, we may use your
                          personal data for our legitimate interests in order to
                          provide you with details about our goods, services,
                          business updates and events.
                        </span>
                      </li>
                    </ul>
                    <p
                      style={{
                        textIndent: "36.0000pt",
                        textAlign: "justify",
                      }}
                    >
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          4.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            HOW WE USE INFORMATION
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          a.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We use the information we collect primarily to
                          provide, maintain, protect, and improve our current
                          products and services.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          b.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We use the information collected through this website
                          as described in this policy and we may use your
                          information to:
                        </span>
                      </li>
                    </ul>
                    <ul style={{ marginLeft: 40, textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          i.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Improve our services, Site and how we operate our
                          businesses;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          ii.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Understand and enhance your experience using our Site,
                          products, and services;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          iii.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Personalize our products or services and make
                          recommendations;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          iv.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Provide and deliver products and services you request;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          v.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Process, manage, complete, and account for
                          transactions;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          vi.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Provide customer support and respond to your requests,
                          comments, and inquiries;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          vii.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Create and manage the online accounts you manage on
                          our website;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          viii.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Send you related information, including confirmations,
                          invoices, technical notices, updates, security alerts
                          and support and administrative messages;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          ix.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Communicate with you about promotions, upcoming
                          events, and news about products and services;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          x.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We may process your personal information without your
                          knowledge or consent where required by applicable law
                          or regulation for the purposes of verification of
                          identity or for prevention, detection, or
                          investigation, including of cyber incidents,
                          prosecution, and punishment of offences;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          xi.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Protect, investigate, and deter against fraudulent,
                          unauthorized, or illegal activity.
                        </span>
                      </li>
                    </ul>
                    <p
                      style={{
                        textIndent: "36.0000pt",
                        textAlign: "justify",
                      }}
                    >
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          5.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            DATA TRANSFER
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          a.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Information about our users is an important part of
                          our business and we take due care to protect the same.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          b.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We share your data with your consent or to complete
                          any transaction or provide any product or service you
                          have requested or authorized. We also share data with
                          our affiliates and subsidiaries, with vendors working
                          on our behalf.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          c.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We may employ other companies and individuals to
                          perform functions on our behalf. The functions include
                          fulfilling orders for products or services, delivering
                          packages, sending postal mail and e-mail, removing
                          repetitive information from customer lists, providing
                          marketing assistance, providing search results and
                          links (including paid listings and links), processing
                          payments, transmitting content, scoring credit risk,
                          and providing customer service.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          d.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          These third-party service providers have access to
                          personal information needed to perform their functions
                          but may not use it for other purposes. Further, they
                          must process the personal information in accordance
                          with this Privacy Policy and as permitted by
                          applicable data protection laws.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          e.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We release accounts and other personal information
                          when we believe it is appropriate to comply with the
                          law, enforce or apply our conditions of use, and other
                          agreements, protect the rights, property or safety of
                          Us, our users, or others. This includes exchanging
                          information with other companies and organizations for
                          fraud protection and credit risk reduction.
                        </span>
                      </li>
                    </ul>
                    <p
                      style={{
                        textIndent: "36.0000pt",
                        textAlign: "justify",
                      }}
                    >
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          6.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            COOKIES
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          a.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          To optimize our web presence, we use cookies. These
                          are small text files stored on your computer's main
                          memory. These cookies are deleted after you close the
                          browser. Other cookies remain on your computer
                          (long-term cookies) and permit its recognition on your
                          next visit. This allows us to improve your access to
                          our site. This helps us to learn more about your
                          interests, and provides you with essential features
                          and services, including:
                        </span>
                      </li>
                    </ul>
                    <ul style={{ marginLeft: 40, textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          i.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Keeping track of items stored in your shopping basket.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          ii.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Conducting research and diagnostics to improve the
                          content, products, and services.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          iii.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Preventing fraudulent activity.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          iv.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Improving security.
                        </span>
                      </li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          b.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Our cookies allow you to take advantage of some of our
                          essential features. For instance, if you block or
                          otherwise reject our cookies, you will not be able to
                          add items to your shopping basket, proceed to
                          checkout, or use any products or services that require
                          you to sign in.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          c.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Approved third parties also may set cookies when you
                          interact with Our services.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          d.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Third parties include search engines, providers of
                          measurement and analytics services, social media
                          networks, and advertising companies.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          e.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Third parties use cookies in the process of delivering
                          content, including ads relevant to your interests, to
                          measure the effectiveness of their ads, and to perform
                          services on behalf of us.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          f.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          You can prevent the storage of cookies by choosing a
                          "disable cookies" option in your browser settings. But
                          this can limit the functionality of our services.
                        </span>
                      </li>
                    </ul>
                    <p
                      style={{
                        textIndent: "36.0000pt",
                        textAlign: "justify",
                      }}
                    >
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          7.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            DATA SECURITY
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          a.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We take due care to protect customer data. Technical
                          measures are in place to prevent unauthorized or
                          unlawful access to data and against accidental loss or
                          destruction of, or damage to, data. The employees who
                          are dealing with the data have been trained to protect
                          the data from any illegal or unauthorized usage.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          b.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We work to protect the security of your information
                          during transmission by using Secure Sockets Locker
                          (SSL) software, which encrypts information you input.
                          SSL allows sensitive information such as credit card
                          numbers, UID's and login credentials to be transmitted
                          securely.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          c.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We follow the Payment Card Industry Data Security
                          Standard (PCI DSS) when handling branded credit cards
                          from the major card schemes.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          d.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We maintain physical, electronic, and procedural
                          safeguards in connection with the collection, storage,
                          and disclosure of personal customer information.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          e.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We take reasonable steps to help protect your personal
                          information in an effort to prevent the loss, misuse,
                          and unauthorized access, disclosure alteration and
                          destruction. It is your responsibility to protect your
                          user names and passwords to help prevent anyone from
                          accessing or abusing your accounts and services. You
                          should not use or reuse the same passwords you use
                          with other accounts as your password for our services.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          f.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          It is important for you to protect against
                          unauthorized access to your password and your
                          computers, devices, and applications. Be sure to sign
                          off when you finish using a shared computer.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          g.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          The information you provide to us is shared on our
                          secure servers. We have implemented appropriate
                          physical, technical and organizational measures
                          designed to secure your information against accidental
                          loss and unauthorized access, use, alteration, or
                          disclosure. In addition, we limit access to personal
                          data to those employees, agents, contractors, and
                          other third parties that have a legitimate business
                          need for such access.
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          h.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Information collected from you will be stored for such
                          period as required to complete the transaction entered
                          into with you or such period as mandated under the
                          applicable laws.
                        </span>
                      </li>
                    </ul>
                    <p
                      style={{
                        textIndent: "36.0000pt",
                        textAlign: "justify",
                      }}
                    >
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          8.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            LINKS TO THIRD PARTY SITE/APPS
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        Our Site may, from time to time, contain links to and
                        from other websites of third parties. Please note that
                        if you follow a link to any of these websites, such
                        websites will apply different terms to the collection
                        and privacy of your personal data, and we do not accept
                        any responsibility or liability for these policies. When
                        you leave our Site, we encourage you to read the privacy
                        policy of every website you visit.
                      </span>
                    </p>
                    <p
                      style={{
                        textIndent: "36.0000pt",
                        textAlign: "justify",
                      }}
                    >
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          9.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            SHARING OF PERSONAL INFORMATION
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          a.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          We do not share your personal data with third parties
                          without your prior consent other than:
                        </span>
                      </li>
                    </ul>
                    <ul style={{ marginLeft: 40, textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          i.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          With third parties who work on our behalf provided
                          such third parties adhere to the data protection
                          principles set out in the IT Act, 2000 (21 of 2000)
                          and other applicable legislation, or enter into a
                          written agreement with Us requiring that the third
                          party provide at least the same level of privacy
                          protection as is required by such principles;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          ii.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          To comply with laws or to respond to lawful requests
                          and legal process;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          iii.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          To protect the rights and property of Us, our agents,
                          customers, and others including to enforce our
                          agreements, policies, and terms of use;
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          iv.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          In an emergency, including to protect the personal
                          safety of any person; and
                        </span>
                      </li>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          v.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          For the purpose of a business deal (or negotiation of
                          a business deal) involving the sale or transfer of all
                          or a part of our business or assets (business deals
                          may include, for example, any merger, financing,
                          acquisition, divestiture, or bankruptcy transaction or
                          proceeding).
                        </span>
                      </li>
                    </ul>
                    <p
                      style={{
                        textIndent: "36.0000pt",
                        textAlign: "justify",
                      }}
                    >
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          10.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            CHILDREN
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        If you are under 18, or the age of majority in the
                        jurisdiction in which you reside, you may only use Our
                        Website with the consent of your parent or legal
                        guardian. In any case, We will not be liable for any
                        cause of action that arose due to non-compliance with
                        this section.
                      </span>
                    </p>
                    <p
                      style={{
                        textIndent: "36.0000pt",
                        textAlign: "justify",
                      }}
                    >
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          11.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            CHANGES TO THIS POLICY
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        We may change this policy from time to time. If we make
                        any changes to this policy, we will change the "Last
                        Updated" date above. You agree that your continued use
                        of our services after such changes have been published
                        to our services will constitute your acceptance of such
                        revised policy.
                      </span>
                    </p>
                    <p
                      style={{
                        textIndent: "36.0000pt",
                        textAlign: "justify",
                      }}
                    >
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <ul style={{ textAlign: "justify" }}>
                      <li className="mb-3">
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            fontSize: "11.0000pt",
                          }}
                        >
                          12.&nbsp;
                        </span>
                        <strong>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontSize: "11.0000pt",
                            }}
                          >
                            NEWSLETTER
                          </span>
                        </strong>
                      </li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                      <li>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          a.&nbsp;
                        </span>
                        <span
                          style={{ fontFamily: "Arial", fontSize: "11.0000pt" }}
                        >
                          Following subscription to the newsletter, your e-mail
                          address is used for our advertising purposes until you
                          cancel the newsletter again. Cancellation is possible
                          at any time. The following consent has been expressly
                          granted by you separately, or possibly in the course
                          of an ordering process: (I am accepting to receive
                          newsletter from this website), you may revoke your
                          consent at any time with future effect. If you no
                          longer want to receive the newsletter, then
                          unsubscribe by clicking on unsubscribe option given in
                          the email footer.
                        </span>
                      </li>
                    </ul>
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        If you have any concern about privacy or grievances with
                        Us, please contact us with a thorough description and we
                        will try to resolve the issue for you.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        Contact Details:&nbsp;
                      </span>
                    </p>
                    <p>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        Mr. Vatsal Raichura
                      </span>
                    </p>
                    <p>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        +91 – 8320295470
                      </span>
                    </p>
                    <p>
                      <a href="mailto:vatsal@virtualcaim.com">
                        <u>
                          <span
                            style={{
                              fontFamily: "Arial",
                              color: "rgb(5,99,193)",
                              textDecoration: "underline",
                            }}
                          >
                            vatsal{"<at>"}virtualcaim{"<dot>"}com
                          </span>
                        </u>
                      </a>
                    </p>
                    <p>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                    <p>
                      <span style={{ fontFamily: "Arial", fontSize: 15 }}>
                        &nbsp;
                      </span>
                    </p>
                  </div>
                  {/* section-title end */}
                </div>
              </div>
              {/* row end */}
            </div>
          </section>
        </section>
      </div>
    );
  }
}

export default PrivacyPolicy;
